// import deliveryType from '@/data/source/local/json/deliveryType.json'
import {
  ref, onMounted, getCurrentInstance, reactive, watch
} from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import {
  courierAbsensiUseCase
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { useRoute } from 'vue-router'
import QrcodeVue from 'qrcode.vue'
import print from 'vue3-print-nb'
import { isDate } from 'lodash'

export default {
  name: 'CourierGenQr',
  props: {
    dataEdit: {
      default: () => null
    }
  },
  directives: {
    print
  },
  components: {
    QrcodeVue,
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const Qrvalue = ref() as any
    const Qrsize = ref(300) as any

    const dataEdit = ref(props.dataEdit)
    const showBtnPrint = ref(false)
    const route = useRoute()
    const store = useStore()
    const submitted = ref(false)
    const agentData = reactive({
      AgenId: null,
      Alamat: null,
      KotaId: null,
      NamaAgen: null,
      NamaKota: null,
    })
    const dataForm = reactive({
      startDate: moment().format('DD-MM-YYYY'),
      endDate: moment().format('DD-MM-YYYY'),
    }) as any

    const rules = {
      startDate: {
        required: helpers.withMessage('Tanggal Mulai harus diisi', required)
      },
      endDate: {
        required: helpers.withMessage('Tanggal Berakhir harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const getMinDate = () => {
      if (dataForm.startDate) {
        if (dataForm.startDate.toString().includes('-')) {
          const arrDate = dataForm.startDate.split('-')
          return new Date(`${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`)
        }
        return new Date(moment(dataForm.startDate).format('YYYY-MM-DD'))
      }
      return new Date('2021-01-01')
    }

    const checkDate = (startDate: any, endDate: any) => {
      if (startDate) {
        if (startDate.toString().includes('-')) {
          const arrDate = startDate.split('-')
          startDate = new Date(`${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`)
        }
        if (endDate) {
          if (endDate.toString().includes('-')) {
            const arrDate = endDate.split('-')
            endDate = new Date(`${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`)
          }

          if (startDate - endDate > 0) {
            dataForm.endDate = null
          }
        }
      } else {
        dataForm.endDate = null
      }
    }

    const proccessSubmit = async () => {
      store.dispatch('showLoading')
      let startDate = null
      let endDate = null
      if (dataForm.startDate.toString().includes('-')) {
        const arrDate = dataForm.startDate.split('-')
        startDate = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`
      } else {
        startDate = `${moment(dataForm.startDate).format('YYYY-MM-DD')}`
      }
      if (dataForm.endDate) {
        if (dataForm.endDate.toString().includes('-')) {
          const arrDate = dataForm.endDate.split('-')
          endDate = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`
        } else {
          endDate = `${moment(dataForm.endDate).format('YYYY-MM-DD')}`
        }
      }
      const { agent } = store.getters['appActiveUser/getAppActiveUser']
      agentData.AgenId = agent.id
      agentData.Alamat = agent.address
      agentData.KotaId = agent?.KotaId
      agentData.NamaAgen = agent.name
      agentData.NamaKota = agent?.NamaKota
      const data = {
        agenId: agentData.AgenId,
        dateStart: startDate,
        dateEnd: endDate
      }

      const response = await courierAbsensiUseCase.createCourierAbsensiKey(data)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        const { Enc } = response.result
        Qrvalue.value = Enc
        showBtnPrint.value = true
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }
    const resetAgentData = () => {
      agentData.AgenId = null
      agentData.Alamat = null
      agentData.KotaId = null
      agentData.NamaAgen = null
      agentData.NamaKota = null
    }
    const submitData = (isFormValid: boolean) => {
      resetAgentData()
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessSubmit()
    }
    function printWQr() {
      return {
        id: 'printMe',
        popTitle: '',
        beforeOpenCallbac: (vue: any) => {
          store.dispatch('showLoading')
        },
        openCallback: (vue: any) => {
          store.dispatch('hideLoading')
        },
      }
    }

    onMounted(() => {
      resetAgentData()
    })

    watch(() => dataForm.startDate, (val: any) => {
      checkDate(val, dataForm.endDate)
    })
    watch(() => dataForm.endDate, (val: any) => {
      checkDate(dataForm.startDate, val)
    })
    return {
      moment,
      dataForm,
      v$,
      route,
      submitted,
      getMinDate,
      submitData,
      Qrvalue,
      Qrsize,
      printWQr,
      agentData,
      isDate,
      showBtnPrint
    }
  }
}
